.container {
  display: flex;
  flex-direction: column;
}

.toggle {
  background-color: var(--hp-color-white);
  border: 0.0625rem solid var(--hp-color-grey-70);
  border-radius: 0.25rem;
  padding: 0.9375rem 0.75rem 0.9375rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  font-size: 0.875rem;

  &:hover {
    border-color: var(--hp-color-teal);
  }

  &:focus {
    border-color: var(--hp-color-teal);
    outline: var(--hp-color-blue-bright-30) solid 0.125rem;
  }

  &_disabled {
    background-color: var(--hp-color-grey-25);
    border-color: var(--hp-color-grey-70);
    opacity: 0.5;
  }
}

.option-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  &_led {
    font-family: var(--font-family-medium);
    font-weight: 500;
  }
}

.label {
  color: var(--hp-color-teal);
  letter-spacing: 0.15;
  font-family: var(--font-family-medium);
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
}

.hint-text {
  color: var(--hp-color-grey-70);
  font-size: 0.875rem;
}

.menu {
  background-color: var(--hp-color-white);
  box-shadow: 0 0.25rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15);
  padding: 0.5rem 0;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    height: 3rem;

    button {
      display: flex;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      border-radius: 0;
      font-size: 0.875rem;
      font-family: var(--font-family-body);
      height: 100%;
      width: 100%;
      align-items: center;
      padding: 0.9375rem 1rem;
    }
  }
}

.option-item {
  &_active {
    background-color: var(--hp-color-grey-5);
    margin: auto -0.0625rem;
    padding: 0 0.0625rem;
  }
}

.option-button {
  &_active {
    color: var(--hp-color-teal);
  }
}

.led {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}
