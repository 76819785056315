.container {
  position: relative;
  width: fit-content;

  .tooltip {
    position: absolute;
    color: white;
    background-color: #575957;
    font-size: 14px;
    white-space: nowrap;
    padding: 4px;
    border-radius: 4px;
    top: calc(100% + 4px);
    display: none;
    z-index: 3;
  }

  .visible {
    display: block;
  }
}
