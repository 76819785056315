.background {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 5;
  display: block;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;

  .container {
    background-color: var(--hp-color-light-grey);
    margin: 5.625rem auto;
    padding: 0;
    border-radius: 8px;
    text-align: center;
    display: inherit !important;
    max-width: 32rem;

    .content {
      padding: 2.0625rem 3rem;
      border-radius: 8px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);

      &--primary {
        text-align: left;
        padding: 1.5rem;

        .row {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;

          .button {
            padding: 0.5rem 1rem;
            cursor: pointer;
          }

          .button:not(:last-child) {
            margin-right: 0.625rem;
          }
        }
      }
      &--secondary {
        text-align: center;
        padding: 3rem;

        .row {
          display: flex;
          flex-direction: column-reverse;
        }
        .button {
          margin-bottom: 1rem;
          cursor: pointer;
        }
      }
    }

    .text {
      margin-bottom: 1.5rem;
    }

    .icon {
      margin-bottom: 0.875rem;
    }

    .close {
      text-align: right;
    }
  }
}
