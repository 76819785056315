.orderDetailsBack {
  text-align: left;
}

.detailsContainer {
  margin: 2rem auto;
  text-align: center;
}

.orderDetailsContent {
  text-align: left;
}

.detailsContainer .closeButton {
  position: relative;
  height: 0;
  font-size: 3em;
  float: right;
  margin-right: -1em;
  margin-top: -1rem;
  cursor: pointer;
  color: var(--hp-color-mid-grey);
}

.detailsContainer .card {
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
}

.detailsContainer .card h3 {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.detailsContainer .card h6 {
  margin-bottom: 0.125rem;
  margin-top: 0.5rem;
  font-weight: 700;
}

.detailsContainer .col-md-8 .card {
  margin-left: 0;
  width: calc(100% - 1rem);
}

.detailsContainer .card .fieldName {
  font-family: var(--font-family-medium);
  font-size: 1rem;
  font-weight: 500;
}

.detailsContainer .card .fieldValue {
  color: var(--hp-color-grey-70);
  font-weight: 400;
  font-size: 1rem;
}

.detailsContainer .medicationCardContent .row {
  margin-top: 0.5rem;
}

.detailsContainer .medicationCardContent .row:first-child {
  margin-top: 0;
}

.detailsContainer .medicationCardContent .row .col:first-child {
  padding-left: 0;
}

.detailsContainer .medicationCardContent .row .col-3 {
  padding-right: 0;
}

.detailsContainer .medicationCardContent .fieldValue {
  font-size: 16px;
}

.detailsContainer .headerCardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.detailsContainer .headerCardContent .headerLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.detailsContainer .headerCardContent .headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.detailsContainer .headerCardContent,
.detailsContainer .headerCardContent .name,
.detailsContainer .headerCardContent .orderNumber {
  margin-bottom: 0;
}

.detailsContainer .headerCardContent .orderNumber {
  color: var(--hp-color-grey-70);
}

.detailsContainer .headerCardContent .printIcon {
  margin-left: 1rem;
}

.detailsContainer .headerCardContent .deliveryIcon {
  margin-right: 10px;
  margin-top: 4px;
}

.detailsContainer .headerCard .errorContainer {
  margin-top: 0.5rem;
}

.detailsContainer .col-md-4,
.detailsContainer .col-md-8 {
  padding: 0;
}

.detailsLogsButton {
  margin-top: 1rem;
}

.orderLogsErrorContainer {
  margin-top: 1rem;
}
