.chip {
  display: flex;
  align-items: center;
  padding: 7px 8px 7px 12px;
  height: 32px;
  background-color: var(--hp-color-grey-5);
  border: 1px solid var(--hp-color-grey-25);
  border-radius: 4px;
  color: var(--hp-color-teal);
  font-size: 14px;

  &:hover {
    border-color: var(--hp-color-teal);
  }

  &:active {
    box-shadow: 0 0 6px 0 #ffd308;
  }

  &:focus {
    outline: 2px solid var(--hp-color-blue-bright-50);
  }
}
