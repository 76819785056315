.snackbar {
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: var(--hp-color-grey-5);
  border: 1px solid var(--hp-color-grey-25);
  border-radius: 7px;
  box-shadow: 0 2px 10px 0 rgba(106, 117, 144, 0.6);
  padding: 16px;

  .icon-container {
    margin-right: 10px;
  }

  .button {
    margin-left: 64px;

    padding: 8px 16px;
    border-radius: 7px;
    background-color: var(--hp-color-pink);
    border: none;
    color: var(--hp-color-dark-grey);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .short-text {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--hp-color-teal);
    font-family: var(--font-family-medium);
    font-weight: 500;
  }

  .long-text {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: var(--hp-color-dark-grey);
  }
}
