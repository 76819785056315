.searchResultContainer {
  background-color: var(--hp-color-white);
  border-radius: 0px 0px 0.5rem 0.5rem;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.0821132);
}

.searchResultContainer > div {
  overflow: visible;
}

.searchResultTable {
  table-layout: fixed;
}

.searchResultTableHeaders {
  border-top: 0 !important;
  border-bottom: 1px solid var(--hp-color-light-grey) !important;
  font-weight: 500;
  font-size: 1rem;
  color: var(--hp-color-mid-grey);
  padding: 1rem 0.5rem !important;
}

.searchResultTableHeaders:first-child {
  padding-left: 2rem !important;
}

.searchResultTableHeaders:last-child {
  padding-right: 2rem !important;
}

.searchResultTableData {
  border-top: 0 !important;
  vertical-align: middle !important;
  padding: 0.5rem !important;
}

.searchResultTableData:first-child {
  padding-left: 2rem !important;
}

.searchResultTableData:last-child {
  padding-right: 2rem !important;
}

.searchResultTableRow:hover {
  background: rgba(134, 206, 225, 0.15);
  cursor: pointer;
}

.searchResultTableRow:hover > .searchResultTableData > .searchResultTableDataUnderline {
  text-decoration: underline;
}

.searchResultError {
  margin: 2rem;
  padding-bottom: 2rem;
}

.rowWithError {
  border-bottom-style: hidden;
}

.selected {
  background-color: var(--hp-color-light-grey);
}

.rdt_Table {
  cursor: pointer;
}

.rdt_TableHeadRow {
  border-bottom-color: var(--hp-color-grey-25);
}

.rdt_TableHeadRow:first-child {
  background-color: var(--hp-color-grey-5);
  font-family: var(--font-family-book);
  font-weight: 500;
  color: var(--hp-color-teal-s-20);
  font-size: 0.875rem;
}

.rdt_TableCol_Sortable > span {
  opacity: 0.5;
}

.rdt_TableCol:hover > .rdt_TableCol_Sortable > span {
  opacity: 1;
}

.desc.__rdt_custom_sort_icon__ {
  transform: rotate(180deg);
}

.newOrdersRow > div:first-child {
  background-color: var(--hp-color-orange-50);
}

.rdt_TableRow {
  padding: 4px 0;
}

.rdt_TableCell > div {
  white-space: normal !important;
}
