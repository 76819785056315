#root {
  background: linear-gradient(180deg, #eeeeee 0%, #f9f9f9 100%);
}

button,
.mock-btn {
  font-family: var(--font-family-medium);
  padding: 1rem 2.5rem;
  color: black;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1.125rem;
}

.hp-btn {
  color: var(--hp-color-white);
  background: var(--hp-color-teal);
  border: none;
}

.hp-btn:hover {
  box-shadow: 0px 0px 5px var(--hp-color-mid-light-grey);
  color: var(--hp-color-white);
  background: var(--hp-color-teal);
  border: none;
  text-decoration: underline;
}

.hp-btn:focus {
  color: var(--hp-color-white);
  background: var(--hp-color-teal);
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.5);
  border: none;
}

.hp-btn:disabled {
  color: var(--hp-color-mid-grey);
  background: var(--hp-color-light-grey);
  border: 4px solid var(--hp-color-mid-grey);
}

.hp-btn--primary {
  background: var(--hp-color-pink);
  color: var(--hp-color-black);
  border: none;
}

.hp-btn--primary:hover {
  background: var(--hp-color-pink);
  color: var(--hp-color-black);
  border: none;
  box-shadow: 0 0 5px var(--hp-color-dark-pink);
}

.hp-btn--primary:focus {
  background: var(--hp-color-pink);
  color: var(--hp-color-black);
  border: none;
  box-shadow: 0px 0px 8px 0px var(--hp-color-dark-pink) inset;
}

.hp-btn--primary:disabled {
  color: var(--hp-color-white);
  background: var(--hp-color-mid-grey);
  border: none;
}
