.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100vh;
}

.page-content-container {
  flex-grow: 2;
}
