.pill {
  align-items: center;
  padding: 8px 16px 6px 16px;
  border-radius: 80px;
  color: var(--hp-color-dark-grey);
  font-weight: 500;
  font-family: var(--font-family-body);

  &--medium {
    font-size: 1rem;
    height: 2.375rem;
  }

  &--small {
    font-size: 0.875rem;
    height: 2.125rem;
  }

  &--extra-small {
    font-size: 0.75rem;
    height: 1.875rem;
  }

  &--information {
    background-color: var(--hp-color-pink-75);
  }

  &--attention {
    background-color: var(--hp-color-yellow-50);
  }

  &--warning {
    background-color: var(--hp-color-orange-50);
  }

  &--critical {
    background-color: var(--hp-color-red-default);
  }

  &--success {
    background-color: var(--hp-color-mint-default);
  }

  &--neutral {
    background-color: var(--hp-color-grey-10);
  }

  &--archive {
    background-color: var(--hp-color-grey-25);
  }
}
