.ripple-container {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.ripple {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  content: '';
  border-radius: 9999px;
  animation: 1.5s ease 1 forwards ripple-effect;
  z-index: 2;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.content {
  position: relative;
}
