.tableCell {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.tableCell p {
  margin: 0px;
  white-space: nowrap;
}

#reportsTooltip {
  opacity: 1;
  min-width: 260px;
  width: 260px;
}

#reportsTooltip p {
  white-space: normal;
}

#reportsTooltip .arrow:before {
  border: 1px solid transparent;
  border-top-color: #969eb1;
  border-left-color: #969eb1;
  content: '';
  position: absolute;
  background-color: #fff;
  transform: translate(0%, 50%) rotate(45deg);
  z-index: 1;
  opacity: 1;
  width: 1rem;
  height: 1rem;
}

#reportsTooltip .tooltip-inner {
  padding: 16px 8px;
  color: #696969;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #969eb1;
  box-shadow: 0px 5px 15px #c8cfe666;
  border-radius: 4px;
}

#reportsTooltip .btn {
  padding: 8px 16px;
  background-color: #f2c4d1;
  color: #282828;
  border-color: transparent;
  margin-top: 8px;
}

#reportsTooltip .btn:hover {
  background-color: #e0b5c1;
}
