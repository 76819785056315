@font-face {
  font-family: 'Campton Book';
  font-style: normal;
  font-weight: normal;
  font-feature-settings: 'liga' 0;
  src: url('../assets/typography/CamptonBook.otf') format('otf'),
    url('../assets/typography/CamptonBook.woff2') format('woff2'),
    url('../assets/typography/CamptonBook.woff') format('woff');
}

@font-face {
  font-family: 'Campton Medium';
  font-style: normal;
  font-weight: normal;
  font-feature-settings: 'liga' 0;
  src: url('../assets/typography/CamptonMedium.otf') format('otf'),
    url('../assets/typography/CamptonMedium.woff2') format('woff2'),
    url('../assets/typography/CamptonMedium.woff') format('woff');
}

@font-face {
  font-family: 'Campton SemiBold';
  font-style: normal;
  font-weight: normal;
  font-feature-settings: 'liga' 0;
  src: url('../assets/typography/CamptonSemiBold.otf') format('otf'),
    url('../assets/typography/CamptonSemiBold.woff2') format('woff2'),
    url('../assets/typography/CamptonSemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Campton Bold';
  font-style: normal;
  font-weight: normal;
  font-feature-settings: 'liga' 0;
  src: url('../assets/typography/CamptonBold.otf') format('otf'),
    url('../assets/typography/CamptonBold.woff2') format('woff2'),
    url('../assets/typography/CamptonBold.woff') format('woff');
}

:root {
  --font-family-body: 'Campton Book', Helvetica, Arial, sans-serif;
  --font-family-bold: 'Campton Bold', Helvetica, Arial, sans-serif;
  --font-family-medium: 'Campton Medium', Helvetica, Arial, sans-serif;
  --font-family-semiBold: 'Campton SemiBold', Helvetica, Arial, sans-serif;
}
