.check-icon {
  height: 32px;
  width: 32px;
}

.reminder-button {
  width: fit-content;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  padding: 0;

  img {
    width: 30px;
  }

  &:hover {
    background-color: var(--hp-color-light-grey);
  }
}
