.tabHeaderContainer {
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.optionSwitchLabel {
  background-color: var(--hp-color-light-grey);
  border-radius: 80px;
  margin-right: 0.5rem;
  padding: 0.34375rem 1rem;
  margin-bottom: 0;
}
.optionSwitchLabel.active {
  background-color: var(--hp-color-ocean-tint);
}

.optionSwitchToggle {
  display: flex;
}

.optionSwitchToggle > label {
  font-size: 1.375rem;
  font-family: var(--font-family-semiBold);
  padding-left: 1rem;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.radiusDropdownButton {
  margin-right: 0.5rem;
}

.custom-control-label::before {
  background-color: var(--hp-color-white);
  border-color: var(--hp-color-teal);
}

.custom-control-input ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  height: 2rem;
  width: 2.75rem;
  border-radius: 1.5rem;
}

.custom-control-input ~ .custom-control-label::before {
  background-color: var(--hp-color-light-grey);
  border-color: var(--hp-color-light-grey);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--hp-color-teal);
  border-color: var(--hp-color-teal);
}

.custom-switch .custom-control-label::after {
  background-color: var(--hp-color-white);
  height: calc(2rem - 4px);
  width: calc(2rem - 4px);
  border-radius: 1.5rem;
}
