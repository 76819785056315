.searchBarContainer {
  margin: 0 auto 1rem;
}

.refreshIcon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-right: 0.75rem;
}

.emptyIcon {
  height: 88px;
  width: 88px;
  margin-bottom: 1.25rem;
}

.bulkLoadingIndicator {
  color: var(--hp-color-teal);
  height: 4rem;
  width: 4rem;
  border: 0.5em solid currentColor;
  border-right-color: transparent;
  margin-bottom: 2rem;
}

.bulkLoadingOverlay {
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  z-index: 5;
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bulkErrorSnackbar {
  visibility: hidden;
  position: fixed;
  bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.show {
  visibility: visible;
}

.tableTab {
  font-weight: 600;
  font-size: 1rem;
  background-color: transparent;
  color: var(--hp-color-mid-grey);
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.5rem 1rem;
  width: 100%;
}

.tableTab:hover {
  text-decoration: underline;
}

.tableTab.active {
  background-color: var(--hp-color-white);
  color: var(--hp-color-teal);
}

.tableTabNumber {
  font-weight: 400;
}

.tableTabList {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}

.tableTabList:first-of-type {
  margin-left: 0;
}

.tableTabList button {
  margin: 0;
}

.tableTabList.filter {
  width: 100px;
  flex-grow: 0;
  justify-content: space-around;
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  margin: 0 8px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  position: relative;
}

.tableTabList.filter button {
  font-size: 0.8rem;
  font-weight: 500;
  color: #282828;
  width: fit-content;
}

.tableTabList.filter img {
  margin-left: 8px;
}

.tableTabList.filter.active {
  border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -ms-border-radius: 8px 8px 0 0;
  -o-border-radius: 8px 8px 0 0;
  border-bottom: white;
}

.tableTabList.filter.active:after {
  content: '';
  position: absolute;
  bottom: -1.5px;
  width: 100%;
  height: 3px;
  background-color: #fff;
  z-index: 100;
}

.tableTabContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  /* flex-wrap: wrap; */
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.tableHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.tableEmptyContainer {
  border-radius: 0px 0px 0.5rem 0.5rem;
  height: 315px;
  background-color: var(--hp-color-white);
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.0821132);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tableEmptyHeader {
  color: var(--hp-color-mid-grey);
  font-size: 1.25rem;
}

.searchBarContainer {
  margin: 0 auto 1rem;
}

.refreshIcon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-right: 0.75rem;
}

.emptyIcon {
  height: 88px;
  width: 88px;
  margin-bottom: 1.25rem;
}

.filterContainer {
  top: 100%;
  right: -1px;
  width: 240px;
  padding: 16px;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  z-index: 1;
  border-radius: 8px 0 8px 8px;
  -webkit-border-radius: 8px 0 8px 8px;
  -moz-border-radius: 8px 0 8px 8px;
  -ms-border-radius: 8px 0 8px 8px;
  -o-border-radius: 8px 0 8px 8px;
}

.filterContainer div {
  justify-content: center;
  align-items: center;
}

.filterContainer div div:first-of-type {
  min-width: 20px;
  min-height: 20px;
}

.filterContainer div div:last-of-type {
  line-height: 1.15rem;
  text-align: left;
}
