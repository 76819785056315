.bulk-operation-bar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  height: 5rem;
  background-color: white;
  width: 100vw;
  margin: 0;
  padding: 0 6rem;

  .left-container,
  .right-container {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .left-container {
    gap: 2rem;
  }

  .total-selected {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-family-semiBold);
  }

  .chip-cross {
    width: 20px;
  }

  .disabled-printer {
    height: 40px;
    width: 40px;
    margin-left: -40px;
  }

  .dropdown {
    margin-right: calc(102px - 6rem + ((100vw - 188px) / 12));
    width: calc(((100vw - 188px) / 6) - 15px);
  }
}
