.forgottenPasswordForm {
  padding: 0 6rem;
}

.forgottenPasswordHeader {
  text-align: center;
}

.forgottenPasswordText {
  margin: 2.15rem 0 2rem 0;
  text-align: center;
  font-size: 1.25rem;
}

.forgottenPasswordButton {
  width: 100%;
}

.forgottenPasswordAltButton {
  width: 100%;
  background-color: var(--hp-color-sorbet);
  margin-bottom: 0.5rem;
}

.forgottenPasswordLabel {
  font-weight: bold;
  display: flex;
}

.showIcon {
  width: 2rem;
}

.emailInput {
  margin-bottom: 2rem;
}

.resend-code-container {
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;
}

.resend-code {
  padding-left: 5px;
  color: var(--hp-color-teal);
  text-decoration: underline;
}

.errors-container {
  font-size: 1rem;
  margin-bottom: 1.25rem;
  font-weight: 500;
  color: var(--hp-color-black);
}
