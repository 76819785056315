.searchInput {
  padding-left: 3rem;
  border-left: 0;
  border-color: var(--hp-color-white);
  background-color: var(--hp-color-white);
  border-radius: 0.5rem !important;
  position: relative;
  height: 3rem;
}

.searchInput:focus {
  border-color: var(--hp-color-white);
}

.searchIconContainer {
  padding-left: 1rem;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  z-index: 4;
  position: absolute;
}

.closeIconContainer {
  position: absolute;
  right: 0.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  z-index: 4;
  cursor: pointer;
}

.searchIcon {
  height: 24px;
  width: 24px;
}

.closeIcon {
  height: 34px;
  width: 34px;
}

.searchGroup {
  margin: 0 auto !important;
  max-width: 750px;
}
