.Amber {
  background-color: #f8d9b8;
}

.Red {
  background-color: #eabfb3;
}

.Amber,
.Red {
  position: absolute;
  left: 0;
  padding: 8px 16px !important;
}
