.dropdownButtonText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdownContainer {
  width: 100%;
}

.dropdownItem {
  display: flex;
  align-items: center;
}
.dropdownItem img {
  margin-left: 8px;
}

.dropdownCircle {
  margin-right: 8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
