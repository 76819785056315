.dropdownButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  padding: 0.53125rem 0.75rem;
}

.dropdownButton:hover {
  box-shadow: 0 0 5px var(--hp-color-mid-grey);
}

.reminderButton,
.reminderButton:hover,
.reminderButton:focus {
  background-color: var(--hp-color-light-grey);
  color: var(--hp-color-black);
  padding: 0.53125rem 0.75rem;
  font-size: 0.875rem;
}

.dropdownItem {
  text-align: center;
  padding: 0.25rem 1rem;
}

.dropdownItem:hover {
  background-color: rgba(134, 206, 225, 0.15);
  text-decoration: underline;
}

.dropdownDivider {
  border-color: var(--hp-color-dark-grey);
  margin: 0.5rem 4rem;
}

.show > .dropdownButton::after {
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.printIcon {
  height: 40px;
  width: 40px;
}

.deliveryIcon {
  margin-right: 10px;
}

.selectAllContainer {
  // To override !importants due to bootstrap table styling
  margin-bottom: -4px;

  svg {
    margin-top: -11px !important;
  }

  [class*='checked'] {
    border-width: 2px !important;
  }

  [class*='ripple'] {
    z-index: 2 !important;
  }
}
