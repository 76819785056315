.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-color: var(--hp-color-white);
  max-width: 79rem;
  max-height: 41rem;
  padding: 2.5rem 2rem;
  margin-top: 1.25rem;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.0821132);
  border-radius: 0.5rem;
  border: 1px solid var(--hp-color-light-grey);
}

.not-found-container h1 {
  color: var(--hp-color-teal);
  margin-bottom: 1.25rem;
}

.not-found-container p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.not-found-face-icon {
  width: 100px;
  margin-bottom: 2.875rem;
}

.not-found-sub-text {
  color: var(--hp-color-mid-grey);
}
