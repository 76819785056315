.scrollable {
  overflow-y: scroll;
  margin: 1rem 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  max-height: 40vh;
  border: 1px solid var(--hp-color-mid-grey);
  border-radius: 0.25rem;
}

.scrollable h1,
.scrollable h3,
.scrollable h4 {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-family: var(--font-family-semiBold);
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

.scrollableNumericList {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.scrollableNumericList .scrollableNumericItem {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.scrollableNumericList .scrollableNumericItem:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

.scrollableNumericList .scrollableNumericItem .scrollableNumericItem {
  margin: 0;
}

.scrollableNumericList .scrollableNumericItem .scrollableNumericItem:before {
  content: counters(item, '.') ' ';
}
