.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-color: var(--hp-color-white);
  max-width: 38rem;
  padding: 2.5rem 2rem;
  margin-top: 1.25rem;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.0821132);
  border-radius: 0.5rem;
  border: 1px solid var(--hp-color-light-grey);
}

.login-container h1 {
  margin-bottom: 2.625rem;
}

.login-container p {
  font-size: larger;
  margin-bottom: 1.5rem;
  text-align: center;
}

.login-container input {
  margin-bottom: 1.5rem;
}

.login-container input,
.login-container button {
  width: 22rem;
}

.login-container button {
  height: 2.5rem;
  padding: 0.5rem;
}

.login-button-container {
  margin-top: 1.5rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.login-error-container {
  margin-top: 0.75rem;
  width: 22rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  font-weight: 400;
}

.verification-code-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.link-blue {
  color: var(--hp-color-teal);
  text-decoration: underline;
}

.login-session-expired-error-container {
  padding-bottom: 1rem;
  width: 36rem;
}
