.reportsContainer .card {
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
  min-width: 17.875rem;
}

.reportsContainer .card .d-flex .ml-4 .title-3 {
  margin-bottom: 0px;
}

.reportsContent {
  width: 100%;
}

.reportsContent > .card {
  margin: 1rem 0;
  width: 100%;
}

.datePickerWrapper {
  width: auto;
}

.dateRangeText {
  align-items: center;
  display: flex;
  margin-bottom: 0;
  margin-left: 1.5rem;
}

.largeValueDisplay {
  font-size: 2.5rem;
}

.deliveryRow {
  display: flex;
  align-items: center;
}

.percentDisplay {
  font-weight: bold;
}

#ReportsFilterContainer .btn-group {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

#ReportsFilterContainer .btn {
  font-size: 14px;
  background-color: #f2f9f9;
  color: #282828;
  border: 1px solid #a9a9a9;
  box-shadow: none;
  padding: 12px;
  width: clamp(80px, 180px, 250px);
}

#ReportsFilterContainer label.btn.active {
  background-color: #008187;
  color: #f2f9f9;
  font-weight: 600;
}

#ReportsFilterContainer .btn.focus,
#ReportsFilterContainer .btn:hover {
  background-color: #e5f2f3;
}

#ReportsFilterContainer .btn.focus {
  border: 1px solid #80c7dc;
}
