.container {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 18px;
    line-height: 1.15;
    font-family: var(--font-family-medium);
  }

  .sub-label {
    font-size: 14px;
    line-height: 1.15;
  }

  .input {
    border: 2px var(--hp-color-mid-grey) solid;
    border-radius: 4px;
    height: 2.5rem;
    padding: 0.5rem;

    &:focus {
      border: 2px var(--hp-color-teal) solid;
      outline: none;
    }
  }

  .password-input {
    display: flex;

    .input {
      width: 100%;
      margin-right: 1rem;

      &:focus {
        border: 2px var(--hp-color-teal) solid;
        outline: none;
      }
    }
  }

  .error-text {
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.15;
  }

  &--error {
    color: var(--hp-color-red);
    border-color: var(--hp-color-red);

    .input {
      color: var(--hp-color-red);

      &:focus {
        border-color: var(--hp-color-red);
      }
    }
  }
}
