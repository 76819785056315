.notificationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--hp-color-orange-10);
  border: 1px solid var(--hp-color-orange-50);
  border-radius: 4px;
  position: relative;
  height: 3rem;

  .frame {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;
    padding-left: 1rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 18px;
  }

  .close-icon-container {
    gap: 8px;
    padding-right: 1rem;
  }

  .title-text {
    padding-left: 1rem;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: var(--hp-color-black);
    font-family: var(--font-family-medium);
    font-weight: 500;
  }

  .description-text {
    padding-left: 1rem;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: var(--hp-color-dark-grey);
  }

  a {
    padding-left: 1rem;
    line-height: 18px;
    color: var(--hp-color-teal);
    font-family: var(--font-family-medium);
    letter-spacing: 0.1px;
  }

  .button-collapse {
    margin-left: 64px;
    padding: 8px 16px;
    border-radius: 7px;
    background-color: var(--hp-color-orange-10);
    border: none;
    color: var(--hp-color-orange-10);
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}
