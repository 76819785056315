.servicesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background-color: var(--hp-color-white);
  padding: 1.5rem;
  margin-top: 1.25rem;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.0821132);
  border-radius: 0.5rem;
  border: 1px solid var(--hp-color-light-grey);
}

.servicesContent {
  width: 100%;
}
