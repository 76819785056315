.settingsColumn {
  padding-left: 0;
  padding-right: 0;
}

.settingsContent {
  text-align: left;
}

.settingsContent .card {
  width: calc(100% - 1rem);
  padding: 1.5rem;
  border-radius: 8px;
}

.settingsContent .row {
  margin-left: 0;
  margin-right: 0;
}

.settingsOptionContainer {
  padding: 1rem;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
}

.settingsOptionContainer > * {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.settingsOptionContainer > h3 {
  margin-right: 0.25rem;
}

.settingsOptionContainer.active {
  background-color: var(--hp-color-light-grey);
  font-family: var(--font-family-semiBold);
}

.settingsOptionContainer.active h3 {
  font-family: var(--font-family-semiBold);
}

.settingsOptionContainer h4 {
  font-family: var(--font-family-medium);
  font-size: 1.125rem;
}

.headerCard h1 {
  margin-bottom: 0;
}

.headerCard.card {
  margin-bottom: 18px;
}
