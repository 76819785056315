.arrowsIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px 0 0;
  width: 1.75rem;
  cursor: pointer;
}

.deliveryPercentIcon {
  width: 2.75rem;
}
