.overlayBackground {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 5;
  display: block;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlayContainer {
  background-color: var(--hp-color-light-grey);
  margin: 5.625rem auto;
  padding: 1rem 4rem;
  border-radius: 8px;
  text-align: center;
  display: inherit !important;
}

.overlayContent {
  text-align: left;
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.overlayContainer .closeButton {
  position: relative;
  height: 0;
  font-size: 3em;
  float: right;
  margin-right: -1em;
  margin-top: -1rem;
  cursor: pointer;
  color: var(--hp-color-mid-grey);
}

.overlayTable,
.overlayTable tr,
.overlayTable td {
  border: 1px solid black;
}
