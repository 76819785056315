.container {
  display: flex;
  flex-direction: column;
  .label {
    font-size: 1rem;
    line-height: 1.15;
    font-family: var(--font-family-medium);
    color: var(--hp-color-teal);
  }

  .dropdown {
    .button {
      border: 1px solid var(--hp-color-grey-70);
      border-radius: 4px;
      padding: 0.9375rem 1rem;
      color: var(--hp-color-grey-70) !important;
      font-size: 1rem;
      font-family: var(--font-family-body);
      background-color: var(--hp-color-white) !important;
      width: 240px;
      display: flex;
      justify-content: space-between;
      line-height: 1.125rem;
      align-items: center;
      &:hover,
      &:active {
        border: 1px solid var(--hp-color-teal);
      }
      &:focus {
        border: 1px solid var(--hp-color-blue-bright-50);
      }

      &:after {
        display: none;
      }

      &--error {
        color: var(--hp-color-red);
        border-color: var(--hp-color-red);
      }

      &--disabled {
        background-color: var(--hp-color-grey-25) !important;
      }

      .chevron {
        height: 18px;
      }
    }

    .menu {
      width: 240px;
      .item {
        font-size: 1rem;
        color: var(--hp-color-dark-grey);
        &:hover {
          color: var(--hp-color-teal);
          background-color: var(--hp-color-grey-5);
        }

        .icon {
          margin-right: 1.125rem;
        }
      }
    }
  }

  .error-text {
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.15;
  }
}
