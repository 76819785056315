.button {
  font-family: var(--font-family-medium);
  padding: 0.5rem 1.5rem;
  color: black;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1.125rem;
  border: none;
}

.button:disabled {
  color: var(--hp-color-mid-grey);
  background: var(--hp-color-light-grey);
}

.button--primary {
  background: var(--hp-color-teal);
  color: var(--hp-color-white);
}

.button--primary:hover {
  box-shadow: 0 0 5px var(--hp-color-mid-light-grey);
}

.button--secondary {
  background: var(--hp-color-pink);
  color: var(--hp-color-black);
}

.button--secondary:hover {
  box-shadow: 0 0 5px var(--hp-color-mid-light-grey);
}

.button--secondary:focus {
  box-shadow: 0px 0px 8px 0px var(--hp-color-dark-pink) inset;
}

.button--tertiary {
  background-color: var(--hp-color-grey-5);
  border: 1px solid var(--hp-color-grey-25);
  color: var(--hp-color-dark-grey);
}

.button--tertiary:hover,
.button--tertiary:active {
  background-color: var(--hp-color-grey-10);
  box-shadow: 0px 0px 10px 0px var(--hp-color-grey-20) inset;
}

.button--tertiary:active {
  box-shadow: 0px 0px 6px var(--hp-color-yellow), 0px 0px 10px 0px var(--hp-color-grey-20) inset;
}

.button--tertiary:focus {
  box-shadow: 0px 0px 6px var(--hp-color-blue-bright);
}

.button--reminder,
.button--reminder:hover,
.button--reminder:focus {
  background-color: var(--hp-color-light-grey);
  color: var(--hp-color-black);
  padding: 0.53125rem 0.75rem;
  font-size: 0.875rem;
}

.button--back,
.button--back:hover,
.button--back:focus {
  background-color: var(--hp-color-white);
  color: var(--hp-color-teal);
  padding: 0.53125rem 0.75rem;
  font-size: 1rem;
  box-shadow: 0 0 5px var(--hp-color-mid-light-grey);
  text-decoration: underline;
  font-family: var(--font-family-semiBold);
}
