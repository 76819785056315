.footer {
  background: var(--hp-color-sorbet-tint);
  padding: 3.625rem 0;
  margin-top: 3.75rem;
}

.footerMenu {
  text-align: center;
  margin: 0;
  padding: 0;
}

.footerMenuItem {
  display: inline-block;
  padding: 0 1rem;
  margin: 0 0.5rem 0 0;
  border-right: 1px solid var(--hp-color-black);
}

.footerMenuItem:last-child {
  border-right: 0;
}

.linkBlack {
  color: var(--hp-color-black);
  font-size: 1rem;
  font-family: var(--font-family-body);
  text-decoration: none;
}

.linkBlack:hover {
  color: var(--hp-color-black);
  text-decoration: underline var(--hp-color-black);
  cursor: pointer;
}

.footerText {
  color: var(--hp-color-black);
  font-size: 1rem;
  font-family: var(--font-family-body);
  text-decoration: none;
}
