.first-time-setup-container {
  background-color: var(--hp-color-white);
  padding: 0;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.0821132);
  border-radius: 8px;
  border: 1px solid var(--hp-color-light-grey);
}

.first-time-setup-container hr {
  margin: 0;
}

.first-time-setup-container h1 {
  margin: 1rem;
}

.first-time-setup-container h2 {
  margin-bottom: 1rem;
}

.first-time-setup-container h3 {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.first-time-setup-container .hp-btn--primary {
  padding: 0.5rem 1.5rem;
}

.first-time-setup-container .chevron {
  height: 1rem;
}

.first-time-setup-container .col {
  padding: 0;
}

.first-time-setup-container .accept-container {
  display: relative;
  bottom: 0;
  text-align: right;
  padding-left: 20%;
}

.first-time-setup-container .accept-cookie-notice {
  font-size: 0.8125rem;
  color: var(--hp-color-grey-70);
  margin: 4rem 0 0.5rem;
}

.first-time-setup-container .accept-cookie-notice button {
  font-size: 1em;
  border: none;
  background: none;
  padding: 0;
  color: var(--hp-color-grey-70);
  text-decoration: underline;
  font-family: var(--font-family-body);
}

.first-time-setup-container .scrollable {
  overflow-y: scroll;
  margin: 1rem 0;
  padding: 1rem;
  width: calc(100% - 2rem);
  max-height: 40vh;
  border: 1px solid var(--hp-color-mid-grey);
  border-radius: 0.25rem;
}

.first-time-setup-container .scrollable h1,
.first-time-setup-container .scrollable h3,
.first-time-setup-container .scrollable h4 {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-family: var(--font-family-semiBold);
}

.first-time-setup-container .container {
  padding: 0;
}

.first-time-setup-container .container .row {
  margin: 0;
}

.first-time-setup-container .content {
  padding: 1rem;
}

.first-time-setup-container .content input {
  max-width: 70%;
}

.first-time-setup-container .timeline {
  background-color: var(--hp-color-light-grey);
  font-weight: 600;
  width: 100%;
  padding: 0;
}

.first-time-setup-container .timeline li {
  margin: 1rem;
}

.first-time-setup-container .timeline .active {
  color: var(--hp-color-teal);
}

.first-time-setup-container .timeline .completed {
  color: var(--hp-color-mid-grey);
}

.first-time-setup-container .alert-danger ul {
  padding-left: 1rem;
}

.first-time-setup-container .password-input {
  margin-bottom: 2rem;
}

.first-time-show-icon {
  width: 2rem;
}

.first-time-setup-container ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.first-time-setup-container ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.password-requirements {
  position: absolute;
  top: 12%;
  left: 82%;
}
