.icon {
  width: 4rem;
}

.largeValueDisplay {
  font-size: 2.5rem;
}

.card-text {
  margin-top: 1rem;
}
