.searchBarResultsFlexContainer {
  display: flex;
  justify-content: center;
}

.searchBarResultsContainer {
  background-color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  position: absolute;
  z-index: 2;
  box-shadow: 0 5px 7px rgb(0 0 0 / 8%);
  width: 100%;
  max-width: 750px;
  overflow-y: scroll;
  max-height: 22rem;
}

.resultsTableContainer {
  background-color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  position: absolute;
  z-index: 2;
  box-shadow: 0 5px 7px rgb(0 0 0 / 8%);
  max-height: 22rem;
  margin-top: 0.5rem;
}

.resultsTableHeaderContainer {
  padding-right: 1rem;
  background-color: var(--hp-color-light-grey);
}

.resultsTableBodyContainer {
  overflow-y: scroll;
  max-height: 18rem;
  background-color: var(--hp-color-white);
}

.resultsTableRow {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 1rem 0;
}

.resultsTableRow.archived {
  background-color: var(--hp-color-grey-5);
}

.resultsTableRow:hover {
  cursor: pointer;
}

.resultsTableList {
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.resultsTableHeading {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 2rem;
  color: var(--hp-color-teal);
}

.resultsTableBody {
  font-weight: 500;
  font-size: 16px;
  color: var(--hp-color-dark-grey);
}

.searchBarErrorContainer {
  padding: 1rem;
}

.searchBarResult {
  padding: 1rem 2rem;
  border-top: 1px solid var(--hp-color-light-grey);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchBarResultEmpty {
  padding: 1rem 2rem;
  border-top: 1px solid var(--hp-color-light-grey);
  display: flex;
  align-items: center;
  max-width: 750px;
}

.searchBarResult p {
  margin-bottom: 0;
}

.searchBarResultName {
  font-family: var(--font-family-medium);
}
