.container {
  display: flex;
}

.checkbox {
  height: 1.25rem;
  width: 1.25rem;
  border: 0.063rem solid var(--hp-color-teal);
  border-radius: 0.125rem;

  &:hover {
    border-width: 0.125rem;
  }

  &:focus {
    outline: 0.125rem solid var(--hp-color-blue-bright-30);
  }

  &_checked {
    background-color: var(--hp-color-teal);
    text-align: center;

    border-width: 0.063rem;

    svg {
      margin-top: -0.5rem;
    }

    &:hover {
      border-width: 0.063rem;
    }
  }

  &_disabled {
    background-color: var(--hp-color-grey-25);
    border-color: var(--hp-color-grey-25);

    &:focus {
      outline: none;
    }
  }
}

.label {
  margin-left: 1.125rem;
  line-height: 1.563rem;

  &_checked {
    color: var(--hp-color-teal);
  }

  &_disabled {
    color: var(--hp-color-grey-25);
  }
}
