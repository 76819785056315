.headerBar {
  background-color: var(--hp-color-sorbet-tint);
  padding: 0 5.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logoImage {
  width: 11.625rem;
  height: 3.188rem;
  margin: 1rem 3rem 1rem 1rem;
}

.navbarInnerLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.navbarInnerRightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.helpAndSupportContainer {
  display: flex;
  flex-direction: row;
}
.helpAndSupport {
  font-size: 14px;
  color: var(--hp-color-teal);
  text-decoration: underline;
  margin-right: 2rem;
  font-weight: bold;
}

.logoutButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.438rem;
  background-color: var(--hp-color-pink);
  color: var(--hp-color-black);
}

.headerLink {
  padding: 1.75rem 0.5rem;
  border-bottom: 3px solid var(--hp-color-sorbet-tint);
  font-family: var(--font-family-medium);
  color: var(--hp-color-teal);
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
}
.headerLink:hover {
  text-decoration: none;
}

.headerLink:visited {
  color: var(--hp-color-teal);
}

.headerLink.currentPage {
  padding: 1.75rem 0.5rem;
  border-bottom: 3px solid var(--hp-color-black);
  font-family: var(--font-family-medium);
  color: var(--hp-color-black);
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
}

.pillIcon {
  display: none;
}

.pillIcon.currentPage {
  display: inline;
  padding-right: 0.688rem;
}

.pillGreenIcon.currentPage {
  display: none;
}

.pillGreenIcon {
  padding-right: 0.688rem;
}

.settingsIcon {
  display: none;
}
.settingsIcon.currentPage {
  display: inline;
  padding-right: 0.688rem;
}

.settingsGreenIcon {
  padding-right: 0.688rem;
}
.settingsGreenIcon.currentPage {
  display: none;
}

.servicesIcon {
  display: none;
}
.servicesIcon.currentPage {
  display: inline;
  padding-right: 0.688rem;
}

.servicesGreenIcon {
  padding-right: 0.688rem;
}
.servicesGreenIcon.currentPage {
  display: none;
}

.reportsIcon {
  display: none;
}
.reportsIcon.currentPage {
  display: inline;
  padding-right: 0.688rem;
}

.reportsGreenIcon {
  padding-right: 0.688rem;
}
.reportsGreenIcon.currentPage {
  display: none;
}

.helpAndSupportIcon {
  padding-right: 0.438rem;
}
