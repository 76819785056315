:root {
  /* Additional Core colours */
  --hp-color-blue-light: #86cee1;

  /* Primary colours */
  --hp-color-pink: #f2c4d1;
  --hp-color-teal: #008187;
  --hp-color-blue-bright: #0090b9;
  --hp-color-blue-bright-30: #b3deea;
  --hp-color-blue-bright-50: #80c7dc;
  --hp-color-black-80: #575656;

  /* Secondary colours */
  --hp-color-sorbet: #f1caa0;
  --hp-color-sorbet-tint: #f8e4cf;
  --hp-color-sorbet-20: #fefaf5;

  /* Grey colours */
  --hp-color-black: #000000;
  --hp-color-dark-grey: #282828;
  --hp-color-mid-grey: #aaaaaa;
  --hp-color-light-grey: #e9e9e9;
  --hp-color-white: #ffffff;

  /* Alert colours */
  --hp-color-green: #006700;
  --hp-color-yellow: #ffd308;
  --hp-color-red: #b60014;

  /* Additional colours */
  --hp-color-mid-light-grey: #d2d2d2;
  --hp-color-light-pink: #fbeeee;
  --hp-color-dark-pink: #e98ea7;
  --hp-color-ocean-tint: #c7f6ef;
  --hp-color-pink-75: #f5d3dc;
  --hp-color-teal-20: #cce6e7;
  --hp-color-teal-s-20: #00676c;
  --hp-color-grey-5: #f4f4f4;
  --hp-color-grey-10: #e9e9e9;
  --hp-color-grey-20: #28282833;
  --hp-color-grey-25: #c9c9c9;
  --hp-color-grey-70: #696969;
  --hp-color-blue-bright-50: #80c7dc;
  --hp-color-yellow-50: #ffe983;
  --hp-color-orange-10: #fdf2e7;
  --hp-color-orange-50: #f3c088;
  --hp-color-red-default: #b92900;
  --hp-color-mint-default: #9ccc92;
}
