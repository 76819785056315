.error-container {
  background-color: var(--hp-color-light-pink);
  padding: 1rem 2rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.error-content {
  display: flex;
  align-items: center;
}

.error-icon {
  padding-right: 1rem;
}

.error-close {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
}
