* {
  font-family: var(--font-family-body);
  font-feature-settings: 'liga' 0;
}

body {
  margin: 0;
  font-family: var(--font-family-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  color: var(--hp-color-dark-grey);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.heroTitle {
  font-family: var(--font-family-bold);
  font-size: 2.5rem;
  color: var(--hp-color-black);
}

h1 {
  font-family: var(--font-family-semiBold);
  font-weight: 600;
  color: var(--hp-color-dark-grey);
  font-size: 1.75rem;
}

h2 {
  font-family: var(--font-family-semiBold);
  font-weight: 600;
  color: var(--hp-color-dark-grey);
  font-size: 1.5rem;
}

h3 {
  font-family: var(--font-family-bold);
  font-weight: 700;
  color: var(--hp-color-dark-grey);
  font-size: 1.375rem;
}

h4 {
  font-family: var(--font-family-bold);
  font-weight: 700;
  color: var(--hp-color-dark-grey);
  font-size: 1.25rem;
}

h5 {
  font-family: var(--font-family-bold);
  font-weight: 700;
  color: var(--hp-color-dark-grey);
  font-size: 1.125rem;
}

.sub-heading-1 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.75rem;
}

.sub-heading-2 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.5rem;
}

.sub-heading-3 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.25rem;
}

.sub-heading-4 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.125rem;
}

.sub-heading-5 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1rem;
}

.title-1 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.75rem;
}

.title-2 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.5rem;
}

.title-3 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.25rem;
}

.title-4 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1.125rem;
}

.title-5 {
  font-family: var(--font-family-medium);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 1rem;
}

.card-value {
  font-family: var(--font-family-semiBold);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 2.5rem;
}

.body-long-copy-book-2 {
  font-family: var(--font-family-body);
  font-weight: 400;
  color: var(--hp-color-dark-grey);
  font-size: 0.875rem;
}

.body-long-copy-semi-bold-2 {
  font-family: var(--font-family-semiBold);
  font-weight: 500;
  color: var(--hp-color-dark-grey);
  font-size: 0.875rem;
}
